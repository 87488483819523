import * as React from 'react';

import Layout from '../components/Layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container py-44">
        <h1 className="heading">Page not found</h1>
        <p>
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
